<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t('backoffice.settings.languages') }}</h4>
      <b-button variant="primary" class="ml-1" @click="submit()">
        {{ $t('form.actions.save') }}
      </b-button>
    </div>
    <b-row class="d-flex flex-wrap">
      <b-col>
        <b-form-group>
          <label for="languages" class="font-weight-bold"> 
             {{ $t("backoffice.settings.available-languages") }}
          </label>
          <v-select
            id="languages"
            v-model="form.availableLanguages"
            multiple
            :searchable="false"
            :placeholder="$t('form.select-placeholder')"
            :options="allLanguages"
            label="name"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="default" class="font-weight-bold"> {{ $t("backoffice.settings.default-language") }}</label>
          <v-select
            id="default"
            v-model="form.defaultLanguage"
            :searchable="false"
            :placeholder="$t('form.select-placeholder')"
            :options="form.availableLanguages"
            label="name"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';

export default {
  name: 'Languages',
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        defaultLanguage: '',
        availableLanguages: [],
      },
      allLanguages: [],
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    languagesOptions() {
      return this.$store.getters.locales;
    },
  },
  async created() {
    await this.getLanguages();
    this.form.defaultLanguage = this.allLanguages.filter(({ name }) => name === this.currentCollective.defaultLanguage?.name || name === this.currentCollective.defaultLanguage);
    this.form.availableLanguages = this.currentCollective.availableLanguages.map((item) => this.allLanguages.filter((code) => item === code.code)[0]);
    this.allLanguages = this.allLanguages.filter(({ name }) => name === 'Español' || name === 'Català' || name === 'English (UK)');
  },
  methods: {
    async submit() {
      const defaultLang = this.form.defaultLanguage.id;
      const languages = this.form.availableLanguages.map((item) => ({ id: item.id }));
      try {
        await this.$store.dispatch('addLanguages', {
          defaultLanguage: defaultLang,
          availableLanguages: languages,
        });
        this.notifySuccess(this.$t('backoffice.settings.messages.success'));
      } catch {
        this.notifyError(this.$t('backoffice.settings.messages.error'));
      }
    },
    async getLanguages() {
      const response = await this.$store.dispatch('getLanguages');
      this.allLanguages = response.data;
    },
  },
};
</script>
